import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetPartnerCompanyListParameters, GetCommunityListParameters, GetPartnerCompanyRes, GetCommunityRes, GetFoodCardXlsxPreviewRes, PostFoodCardDataParameters, GetKioskVendingListParameters, GetKioskVendingRes, PutFoodCardParameters, GetQuotaRes, GetFoodCardUserMemberParameters, GetQuotaListParameters, DeleteQuotaFoodCardParameters, PostQuotaFoodCardParameters, GetQuotaFoodCardParameters, GetQuotaFoodCardRes, GetQuotaFoodCardListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { DatePicker, Select, Tag, Input, Spin, Checkbox, Space } from 'antd';
import moment from 'moment';
import JJ_FromSwitch from '../../components/JJ_FromSwitch';
import JJ_FromUploadXlsx from '../../components/JJ_FromUploadXlsx';
import { RcFile } from 'antd/lib/upload/interface';
import config from '../../config';
import FoodCardXlsxPreViewModal from './FoodCardXlsxPreViewModal';
import { ActionUserRes } from '../../actions/ActionUser';
import { getI18nText } from '../../public';


interface PageState {
  kioskVendings: GetKioskVendingRes[]
  foodCardXlsxPreviews?: GetFoodCardXlsxPreviewRes[]
  foodCardXlsxPreViewModal: {
    key: string
    visible: boolean
  }
  spinLoading?: boolean
  quotas: GetQuotaRes[]
  quotaFoodCards: GetQuotaFoodCardRes[]
}

type Type = { type: 'edit', id: string } | { type: 'edits', ids: string[] } | { type: 'addXlsx' } | { type: 'addFamily' } | { type: 'addFamilyMember' }

interface Props {
  onFinish?(props: PageProps, data: any): void
  user: ActionUserRes['data']

}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & Type & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      kioskVendings: [],
      foodCardXlsxPreViewModal: {
        key: new Date().toISOString(),
        visible: false,
      },
      spinLoading: false,
      quotas: [],
      quotaFoodCards: [],
    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log(values)


    switch (this.props.type) {


      case 'edit': {
        const quotas: string[] = values.quotas
        if (quotas) {
          //刪除所有食品卡配額
          for (const quotaFoodCard of this.state.quotaFoodCards) {
            await this._deleteQuotaFoodCard({ id: quotaFoodCard.id })
          }

          //添加新的食品卡配額
          for (const quota of quotas) {
            await this._postQuotaFoodCard({ foodCardId: this.props.id, quotaId: quota })
          }
        }
        const res = await api.PutFoodCard({
          ...values,
          endAt: values.endAt ? moment(values.endAt).endOf('day').toISOString() : undefined,
          beginAt: values.beginAt ? moment(values.beginAt).startOf('day').toISOString() : undefined,
          id: this.props.id,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data.id)
      }
        break
      case 'edits': {

        const quotas: string[] = values.quotas


        const data = await Promise.all(
          this.props.ids.map(async id => {
            const putData: PutFoodCardParameters = {
              id,
            }

            if (!values.isEnableSkipped) {
              putData.isEnable = values.isEnable
            }

            if (!values.beginAtSkipped) {
              putData.beginAt = values.beginAt ? moment(values.beginAt).startOf('day').toISOString() : null
            }

            if (!values.endAtSkipped) {
              putData.endAt = values.endAt ? moment(values.endAt).endOf('day').toISOString() : null
            }

            if (quotas) {
              //刪除所有食品卡配額

              const quotaFoodCards = await this._GetQuotaFoodCardList({ count: 1000, foodCardId: id })

              for (const quotaFoodCard of quotaFoodCards.data) {
                await this._deleteQuotaFoodCard({ id: quotaFoodCard.id })
              }

              //添加新的食品卡配額
              for (const quota of quotas) {
                await this._postQuotaFoodCard({ foodCardId: id, quotaId: quota })
              }
            }

            const res = await api.PutFoodCard(putData)
            if (res.kind !== 'ok') throw Error(res.kind)
            return res.data
          })
        )
        this.props.onFinish && this.props.onFinish(this.props, data)
      }
        break
      case 'addXlsx': {
        const file: RcFile = values.file[0]
        const res = await api.PostFoodCardXlsxPreview({
          kioskVendingId: values.kioskVendingId,
          isEnable: values.isEnable,
          beginAt: values.beginAt ? moment(values.beginAt).startOf('day').toISOString() : undefined,
          file: file,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.setState({
          foodCardXlsxPreViewModal: { ...this.state.foodCardXlsxPreViewModal, visible: true, key: moment().startOf('day').toISOString() },
          foodCardXlsxPreviews: res.data.data,
        })
      }
        break
      case 'addFamily': {
        const res = await api.PostFoodCardFamily({
          kioskVendingId: values.kioskVendingId,
          memberCount: values.memberCount,
          isEnable: values.isEnable,
          beginAt: values.beginAt ? moment(values.beginAt).startOf('day').toISOString() : undefined,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data)
      }
        break
      case 'addFamilyMember': {

        const res = await api.PostFoodCardFamilyMember({
          code: values.code,
          memberCount: values.memberCount,
          isEnable: values.isEnable,
          beginAt: values.beginAt ? moment(values.beginAt).startOf('day').toISOString() : undefined,
        })
        if (res.kind !== 'ok') throw Error(res.kind)
        this.props.onFinish && this.props.onFinish(this.props, res.data)

      }
        break
    }
  }

  _getQuotaList = async (param: GetQuotaListParameters) => {
    const res = await api.GetQuotaList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _deleteQuotaFoodCard = async (param: DeleteQuotaFoodCardParameters) => {
    const res = await api.DeleteQuotaFoodCard(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postQuotaFoodCard = async (param: PostQuotaFoodCardParameters) => {
    const res = await api.PostQuotaFoodCard(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _GetQuotaFoodCardList = async (param: GetQuotaFoodCardListParameters) => {
    const res = await api.GetQuotaFoodCardList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    const quotaList = await this._getQuotaList({ count: 1000 })

    this.setState({
      quotas: quotaList.data,

    })

    switch (this.props.type) {
      case 'edit': {
        const res = await api.GetFoodCard({ id: this.props.id })
        const quotaFoodCardList = await this._GetQuotaFoodCardList({ count: 1000, foodCardId: this.props.id })

        this.setState({
          quotaFoodCards: quotaFoodCardList.data,
        })
        if (res.kind === 'ok') {
          const beginAt = res.data.beginAt ? moment(res.data.beginAt) : res.data.beginAt
          const endAt = res.data.endAt ? moment(res.data.endAt) : res.data.endAt
          return {
            ...res.data,
            beginAt,
            endAt,
            quotas: quotaFoodCardList.data.map(item => item.quota)
          }
        }
        throw Error(res.kind)
      }
      case 'edits': {
        let isEnableShared = undefined;
        let beginAtShared = undefined;
        let endAtShared = undefined;

        let isEnableSkipped = false;
        let beginAtSkipped = false;
        let endAtSkipped = false;


        for (let id of this.props.ids) {
          const res = await api.GetFoodCard({ id })

          if (res.kind === 'ok') {
            const beginAt = res.data.beginAt ? moment(res.data.beginAt) : res.data.beginAt
            const endAt = res.data.endAt ? moment(res.data.endAt) : res.data.endAt

            if (isEnableShared === undefined) {
              isEnableShared = res.data.isEnable;
              beginAtShared = beginAt;
              endAtShared = endAt;
            } else {
              if (isEnableShared !== res.data.isEnable) {
                isEnableSkipped = true;
              }
              if (beginAtShared !== beginAt) {
                beginAtSkipped = true;
              }
              if (endAtShared !== endAt) {
                endAtSkipped = true;
              }
            }
          }
        }

        return {
          isEnable: isEnableShared,
          beginAt: beginAtShared,
          endAt: endAtShared,
          isEnableSkipped,
          beginAtSkipped,
          endAtSkipped,

        }
      }
      case 'addXlsx': {
        const kioskVendingList = await this._getKioskVendingList({ count: 10000 })

        this.setState({
          kioskVendings: kioskVendingList.data
        })
        return { isEnable: true }
      }
      case 'addFamily': {
        const kioskVendingList = await this._getKioskVendingList({ count: 10000 })

        this.setState({
          kioskVendings: kioskVendingList.data
        })

        return { isEnable: true }
      }
      case 'addFamilyMember': {

        return { isEnable: true }
      }

    }
  };


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'endAt',
            label: '停用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'divider',
          },

          {
            id: 'quotas',
            label: '配額',
            rules: [
              {
                required: true,
                message: `請選擇 配額`
              }
            ],
            componet: (
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="請選擇 配額"
                optionLabelProp="label"
              >
                {this.state.quotas.map(quota => {
                  return (
                    <Select.Option
                      key={quota.id}
                      value={quota.id}
                      label={getI18nText(quota.name, this.props.user.language)}
                    >
                      <Space>
                        <Tag>
                          {getI18nText(quota.name, this.props.user.language)}
                        </Tag>
                        <span>
                          {`每月${quota.monthCount}`}
                        </span>
                        <span>
                          {`每周${quota.weekCount}`}
                        </span>
                        <span>
                          {`每日${quota.dayCount}`}
                        </span>
                      </Space>
                    </Select.Option>
                  )
                })}



              </Select>
            )
          },

          {
            id: 'submit',
          },
        ]
      }
      case 'edits': {
        return [
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'isEnableSkipped',
            label: '　',
            rules: [
              {
                required: false,
              }
            ],
            componet: (<Checkbox style={{ color: '#F00' }}>不更新此欄</Checkbox>),
            props: {
              colon: false,
              valuePropName: 'checked',
            },
          },
          
          {
            id: 'quotas',
            label: '配額',
            rules: [
              {
                required: true,
                message: `請選擇 配額`
              }
            ],
            componet: (
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="請選擇 配額"
                optionLabelProp="label"
              >
                {this.state.quotas.map(quota => {
                  return (
                    <Select.Option
                      key={quota.id}
                      value={quota.id}
                      label={getI18nText(quota.name, this.props.user.language)}
                    >
                      <Space>
                        <Tag>
                          {getI18nText(quota.name, this.props.user.language)}
                        </Tag>
                        <span>
                          {`每月${quota.monthCount}`}
                        </span>
                        <span>
                          {`每周${quota.weekCount}`}
                        </span>
                        <span>
                          {`每日${quota.dayCount}`}
                        </span>
                      </Space>
                    </Select.Option>
                  )
                })}
              </Select>
            )
          },


          {
            id: 'submit',
          },
        ]
      }
      case 'addFamily': {
        return [
          {
            id: 'kioskVendingId',
            label: '取餐機',
            rules: [
              {
                required: true,
                message: `請選擇取餐機`,
              }
            ],
            componet: (
              <Select
                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {this.state.kioskVendings.map(kioskVending => {
                  return (
                    <Select.Option
                      key={kioskVending.id}
                      value={kioskVending.id}
                    >
                      {kioskVending.code}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'memberCount',
            label: '總成員數量',
            rules: [
              {
                required: true,
                message: `總成員數量不能大於99和少於0`,
                validator: async (rule, value: number) => {
                  value = Number(value)
                  if (value > 99 || value <= 0) throw new Error()
                }
              }
            ],
            componet: (<Input placeholder='請輸入總成員數量' type='number' />)
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'addFamilyMember': {
        return [
          {
            id: 'code',
            label: '代號',
            extra: '截取卡號家庭前面例如：DEV-01-FA1-001',
            rules: [
              {
                required: true,
                message: `請輸入代號`,
                min: 14,
                max: 14,
              }
            ]
          },
          {
            id: 'memberCount',
            label: '追加成員數量',
            rules: [
              {
                required: true,
                message: `追加成員數量不能大於99和少於0`,
                validator: async (rule, value: number) => {
                  value = Number(value)
                  if (value > 99 || value <= 0) throw new Error()
                }
              }
            ],
            componet: (<Input placeholder='請輸入追加成員數量' type='number' />)
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'addXlsx': {
        return [

          {
            id: 'kioskVendingId',
            label: '取餐機',
            rules: [
              {
                required: true,
                message: `請選擇取餐機`,
              }
            ],
            componet: (
              <Select

                showSearch={true}
                filterOption={(input: any, option) => {
                  if (option && option.children && typeof (option.children) === 'string') {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  return false
                }}
              >
                {this.state.kioskVendings.map(kioskVending => {
                  return (
                    <Select.Option
                      key={kioskVending.id}
                      value={kioskVending.id}
                    >
                      {kioskVending.code}
                    </Select.Option>
                  )
                })}
              </Select>
            ),
          },
          {
            id: 'isEnable',
            label: '是否啟用',
            rules: [
              {
                required: true,
              }
            ],
            componet: (<JJ_FromSwitch />)
          },
          {
            id: 'file',
            label: 'Excel檔案',
            extra: (
              <div>
                {'下載 '}
                <a target='_blank' href={`${config.REACT_APP_STATIC}/images/card-template.xlsx`}>Excel模板</a>
              </div>
            ),
            rules: [
              {
                required: true,
                message: `請上傳Excel檔案`,
              }
            ],
            componet: (<JJ_FromUploadXlsx />)
          },
          {
            id: 'beginAt',
            label: '啟用時間',
            rules: [
              {
                required: false,
              }
            ],
            componet: (
              <DatePicker picker='date' />
            )
          },

          {
            id: 'submit',
            label: '預覽',
          },
        ]
      }

    }
  }

  _postFoodCardData = async (params: PostFoodCardDataParameters) => {
    const res = await api.PostFoodCardData(params)
    if (res.kind !== 'ok') throw new Error
    return res.data
  }

  render() {
    return (
      <div id="FoodCardEditAndAdd">
        <Spin spinning={this.state.spinLoading}>
          <JJ_From
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
          {this.state.foodCardXlsxPreviews && (
            <FoodCardXlsxPreViewModal
              key={this.state.foodCardXlsxPreViewModal.key}
              title={'預覽生成數據'}
              foodCardXlsxPreviews={this.state.foodCardXlsxPreviews}
              visible={this.state.foodCardXlsxPreViewModal.visible}
              onCancel={() => this.setState({ foodCardXlsxPreViewModal: { ...this.state.foodCardXlsxPreViewModal, visible: false } })}
              onFinish={async (res) => {
                try {
                  this.setState({
                    foodCardXlsxPreViewModal: { ...this.state.foodCardXlsxPreViewModal, visible: false },
                    spinLoading: true,
                  })

                  const postFoodCards = await this._postFoodCardData({
                    data: res.map(item => ({
                      code: item.code,
                      beginAt: item.beginAt,
                      isEnable: item.isEnable,
                      partnerCompanyId: item.partnerCompany.id,
                      communityId: item.community.id,
                    }))
                  })
                  this.setState({
                    spinLoading: false,
                  })
                  this.props.onFinish && this.props.onFinish(this.props, postFoodCards.data)
                } catch (error) {
                  console.log(error)
                  this.setState({
                    spinLoading: false,
                  })
                }

              }}
            />
          )}
        </Spin>
      </div>
    )
  }

}
export default connector(FoodCardEditAndAdd)


