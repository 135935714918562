import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import api, { GetKioskVendingListParameters, GetKioskVendingRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';
import KioskVendingEditAndAdd from './KioskVendingEditAndAdd';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetKioskVendingRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class KioskVendingList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetKioskVendingRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _getColumns = (): ColumnsType<GetKioskVendingRes> => {
    return [
      {
        key: 'action',
        width: 50,
        render: (value: any, record) => {
          return (
            <JJ_TableItemAction
              menus={[
                {
                  type: 'item',
                  disabled: false,
                  key: 'edit',
                  name: '編輯',
                  onAction: () => {
                    this.setState(state => ({
                      editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                    }))
                  }
                },
                {
                  type: 'item',
                  disabled: config.REACT_APP_DELETE_DISABLED,
                  key: 'delete',
                  name: '刪除',
                  onAction: () => {
                    confirm({
                      title: `是否删除${record.name}`,
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Yes',
                      okType: 'danger',
                      cancelText: 'No',
                      onOk: async () => {
                        try {
                          await api.DeleteKisokVending({ id: record.id })
                          this.tableRef && this.tableRef.refreshData()
                          message.success(`${record.name} 删除成功`)
                        } catch (error) {
                          message.error(`${record.name} 删除失败 [${error.message}]`);
                        }
                      },
                      onCancel() {
                      },
                    });
                  }
                },
              ]}
            />
          )

        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (value) => (
          <JJ_TableItemCopyableId
            text={value}
          />
        )
      },
      {
        title: '名稱',
        dataIndex: 'name',
        key: 'name',
        width: 250,
      },
      {
        title: '地址',
        dataIndex: 'address',
        key: 'address',
        width: 400,
        render: (value) => (
          <div style={{ maxWidth: 400 }}>
            <span>{value}</span>
          </div>
        )
      },
      {
        title: '開放時間',
        dataIndex: 'openHour',
        key: 'openHour',
        width: 250,
      },
      {
        title: '代號',
        dataIndex: 'code',
        key: 'code',
        width: 150,
      },
      {
        title: '機器ID',
        dataIndex: 'kioskId',
        key: 'kioskId',
        width: 100,
        render: (value) => (
          <JJ_TableItemCopyableText
            text={value}
          />
        )
      },
      {
        title: '是否啟用',
        width: 100,
        dataIndex: 'isEnable',
        key: 'isEnable',
        render: (value, record) => (
          record.isEnable
            ? <Tag color="blue">啟用</Tag>
            : <Tag color="red">停用</Tag>
        )
      },
      {
        title: '申請個案可選',
        width: 100,
        dataIndex: 'isSelectable',
        key: 'isSelectable',
        render: (value, record) => (
          record.isSelectable
            ? <Tag color="blue">可選</Tag>
            : <Tag color="red">已停</Tag>
        )
      },
      {
        title: '社區名稱',
        dataIndex: ['community', 'name'],
        key: 'community.name',
        width: 150,

      },
      {
        title: '創建時間',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 150,

        render: (value) => (
          <JJ_TableItemMoment
            date={value}
          />
        )
      },
    ]
  }

  render() {
    return (
      <div id="KioskVendingList"
      >
        <Button
          type='primary'
          style={{ marginBottom: 5 }}
          onClick={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'add' }
          }))}
          icon={<PlusOutlined />}
        >
          {`添加取餐機`}
        </Button>
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯取餐機' : '新建取餐機'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <KioskVendingEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetKioskVendingRes, {
              desc: 'ascend' | 'descend'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
              ]}
              columns={this._getColumns()}
              tableProps={{
                scroll: {
                  x: this._getColumns().reduce((pv, cv) => pv + Number((cv?.width || 0)), 0),
                }
              }}
              title={'取餐機清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetKioskVendingListParameters['isAscend']
                  scope?: GetKioskVendingListParameters['scope']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                }
                params.scope = ['community']
                const res = await api.GetKioskVendingList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(KioskVendingList)

