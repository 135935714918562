import React, { } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Home from "../home/Home";
import AreaList from '../area/AreaList';
import CommunityList from '../community/CommunityList';
import KioskVendingList from '../kioskVending/KioskVendingList';
import KioskVendingSellWeekList from '../kioskVending/KioskVendingSellWeekList';
import KioskVendingMemberMonthList from '../kioskVending/KioskVendingMemberMonthList';
import PartnerCompanyList from '../partnerCompany/PartnerCompanyList';
import FoodCardList from '../foodCard/FoodCardList';
import FoodCardGroupList from '../foodCardGroup/FoodCardGroupList';
import FoodCardRecordList from '../foodCardRecord/FoodCardRecordList';
import FoodCardUserMemberFamilyList from '../foodCardUserMemberFamily/FoodCardUserMemberFamilyList';
import FoodCardUserMemberList from '../foodCardUserMember/FoodCardUserMemberList';
import FoodMenuList from '../foodMenu/FoodMenuList';
import FoodCardUserMemberFamilyAreaList from '../foodCardUserMemberFamilyArea/FoodCardUserMemberFamilyAreaList';
import SurveyList from '../survey/SurveyList';
import ReplenishRequest from '../replenishRequest/ReplenishRequestList';
import ImageLibraryList from '../imageLibrary/ImageLibraryList';
import ProductList from '../product/ProductList';
import LabelList from '../label/LabelList';
import HealthTipList from '../healthTip/HealthTipList';
import MessageList from '../message/MessageList';
import SignupFormList from '../signupForm/SignupFormList';
import QuotaList from '../quota/QuotaList';


interface PageState {


}


interface PageProps {

}

const { Content } = Layout;

export default class MainContent extends React.Component<PageProps, PageState>  {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    };
  }

  render() {

    return (
      <Content
        className="site-layout-background"
        style={{
          margin: '24px 16px',
          padding: 24,
          overflow: 'auto',
        }}
      >
        <Switch>
          <Route path="/area/list" exact component={AreaList}></Route>
          <Route path="/community/list" exact component={CommunityList}></Route>
          <Route path="/kiosk-vending/list" exact component={KioskVendingList}></Route>
          <Route path="/partner-company/list" exact component={PartnerCompanyList}></Route>
          <Route path="/food-card/list" exact component={FoodCardList}></Route>
          <Route path="/food-card-group/list" exact component={FoodCardGroupList}></Route>
          <Route path="/food-card-record/list" exact component={FoodCardRecordList}></Route>
          <Route path="/kiosk-vending/sell-week/list" exact component={KioskVendingSellWeekList}></Route>
          <Route path="/kiosk-vending/member-month/list" exact component={KioskVendingMemberMonthList}></Route>
          <Route path="/food-card-user-member-family/list" exact component={FoodCardUserMemberFamilyList}></Route>
          <Route path="/food-card-user-member-family-area/list" exact component={FoodCardUserMemberFamilyAreaList}></Route>
          <Route path="/food-card-user-member/list" exact component={FoodCardUserMemberList}></Route>
          <Route path="/survey/list" exact component={SurveyList}></Route>
          <Route path="/image-library/list" exact component={ImageLibraryList}></Route>
          <Route path="/product/list" exact component={ProductList}></Route>
          <Route path="/health-tip/list" exact component={HealthTipList}></Route>
          <Route path="/product-label/list" exact component={LabelList}></Route>
          <Route path="/replenish-request" exact component={ReplenishRequest}></Route>
          <Route path="/message" exact component={MessageList}></Route>
          <Route path="/food-card-user-member-family/signup-form/list" exact component={SignupFormList}></Route>
          <Route path="/quota/list" exact component={QuotaList}></Route>
          <Route component={FoodCardRecordList} />
        </Switch>
      </Content>
    )
  }

}

[].reduce((previousValue, currentValue, currentIndex, array: any[]) => {
  return {
    ...previousValue,
  }
}, {
  time: '',
  count: 0,
})