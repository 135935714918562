import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyParameters, PostFoodCardUserMemberFamilyPausedRes, PostFoodCardUserMemberFamilyPausedParameters, PostFoodCardUserMemberFamilyResumeRes, PostFoodCardUserMemberFamilyResumeParameters, PostFoodCardUserMemberFamilyTerminatedRes, PostFoodCardUserMemberFamilyTerminatedParameters, PostFoodCardUserMemberFamilyStartRes, PostFoodCardUserMemberFamilyStartParameters } from '../../api';
import { Modal, message, Spin, Layout, Space, DatePicker } from 'antd';
import { Page } from '../home/Home.interface';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';


interface PageState {
  spinning?: boolean
}


export interface Props {
  id: string
  visible?: boolean
  type: string
  onOk: () => void
  onCancel: () => void
}

type PageProps = Props


class FoodCardUserMemberFamilyChangeDateModal extends React.Component<PageProps, PageState> implements Page {

  formRef: (
    FormInstance<{
    }> | null
  ) | undefined

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      spinning: false,
    }
  }

  componentDidMount() {
  }

  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'start': {
        return [
          {
            id: 'beginAt',
            label: '開始日期',
            rules: [
              {
                required: true,
                message: `請輸入 開始日期`
              }
            ],
            componet: (
              <DatePicker
                picker='date'
                disabledDate={current => current && current < moment().add(1, 'day').startOf('day')}
              />
            ),
          },
        ]
      }
      case 'paused': {
        return [
          {
            id: 'reason',
            label: '暫停原因',
            rules: [
              {
                required: true,
                message: `請輸入 暫停原因`
              }
            ],
          },
          {
            id: 'estimatedDateServiceResume',
            label: '預計恢復日期',
            rules: [
              {
                required: true,
                message: `請輸入 預計恢復日期`
              }
            ],
            componet: (
              <DatePicker picker='date' />
            ),
          },
        ]
      }
      case 'resume': {
        return [
          {
            id: 'text',
            componet: (
              <span>確定要立即恢復此家庭的服務?</span>
            ),
          },
        ]
      }
      case 'terminated': {
        return [
          {
            id: 'text',
            componet: (
              <span>確定要立即終止此家庭的服務? (不能還原!)</span>
            ),
          },
        ]
      }
    }
    return []
  }

  _onFinish = async (values: any): Promise<void> => {

    this.setState({
      spinning: true,
    })

    console.log('_onFinish', values)

    switch (this.props.type) {
      case 'start': {
        const { beginAt } = values;

        const finish = async () => {
          const res = await this._postFoodCardUserMemberFamilyStart({
            id: this.props.id,
            beginAt: moment(beginAt).startOf('day').toISOString(),
          })

          this.props.onOk()
        }

        if (moment().diff(beginAt, 'day') > -3) {
          Modal.confirm({
            title: `選擇的日子少於三天, 用戶不會收到3天前的短訊, 確定要繼續?`,
            okText: '確定',
            okType: 'danger',
            cancelText: '返回',
            onOk: () => {
              finish();
            },
            onCancel: () => {
              this.setState({
                spinning: false,
              })
            },
          });
        } else {
          finish();
        }
        return;
      }
      case 'paused': {
        const { reason, estimatedDateServiceResume } = values;

        const res = await this._postFoodCardUserMemberFamilyPaused({
          id: this.props.id,
          reason,
          estimatedDateServiceResume: moment(estimatedDateServiceResume).startOf('day').toISOString(),
        })
        break
      }
      case 'resume': {
        const res = await this._postFoodCardUserMemberFamilyResume({
          id: this.props.id,
        })
        break
      }
      case 'terminated': {
        const res = await this._postFoodCardUserMemberFamilyTerminated({
          id: this.props.id,
        })
        break
      }
    }

    this.props.onOk()
  }

  _onLoadData = async (): Promise<any> => {
    const res = await this._getFoodCardUserMemberFamily({ id: this.props.id })
    return {
      ...res,
      beginAt: res.beginAt ? moment(res.beginAt) : null,
      estimatedDateServiceResume: res.estimatedDateServiceResume ? moment(res.estimatedDateServiceResume) : null,
    }
  };

  _getFoodCardUserMemberFamily = async (param: GetFoodCardUserMemberFamilyParameters) => {
    const res = await api.GetFoodCardUserMemberFamily(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilyPaused = async (param: PostFoodCardUserMemberFamilyPausedParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyPaused(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilyResume = async (param: PostFoodCardUserMemberFamilyResumeParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyResume(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilyTerminated = async (param: PostFoodCardUserMemberFamilyTerminatedParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyTerminated(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _postFoodCardUserMemberFamilyStart = async (param: PostFoodCardUserMemberFamilyStartParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyStart(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  render() {

    let title = '';
    switch (this.props.type) {
      case 'start': {
        title = '修改服務開始日期';
        break;
      }
      case 'paused': {
        title = '立即暫停服務';
        break;
      }
      case 'resume': {
        title = '立即恢復服務';
        break;
      }
      case 'terminated': {
        title = '強制終止服務';
        break;
      }
    }

    return (
      <Modal
        maskClosable={false}
        title={title}
        visible={this.props.visible}
        onOk={() => this.formRef && this.formRef.submit()}
        onCancel={this.props.onCancel}
        width="80%"
      >
        <Spin
          spinning={this.state.spinning} style={{width: '100%'}}
        >
          <JJ_From
            formRef={res => this.formRef = res}
            formListData={this._formListData()}
            onLoadData={this._onLoadData}
            onFinish={this._onFinish}
          />
        </Spin>
      </Modal>

    )
  }

}

export default FoodCardUserMemberFamilyChangeDateModal
