import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardUserMemberFamilyListParameters, GetFoodCardUserMemberFamilyRes, DeleteFoodCardUserMemberFamilyParameters, GetFoodCardUserMemberListParameters, GetFoodCardUserMemberRes, GetFoodCardUserMemberFamilyAreaListParameters, GetFoodCardUserMemberFamilyAreaRes, GetFoodCardUserMemberFamilyOldRes, GetFoodCardUserMemberFamilyOldListParameters, GetFoodCardRes, GetFoodCardParameters, GetFoodCardListParameters, GetKioskVendingRes, GetKioskVendingListParameters, PostFoodCardUserMemberFamilyChangePasswordParameters, GetFoodCardUserMemberFamilyStatusRes, PostFoodCardUserMemberFamilyRenewalParameters, GetFoodCardUserMemberFamilyParameters, PostFoodCardUserMemberFamilyResetFoodCardCodeSignParameters } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import FoodCardUserMemberFamilyEditAndAdd, { FoodCardUserMemberFamilyEditAndAddType } from './FoodCardUserMemberFamilyEditAndAdd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import FoodCardUserMemberFamilyDetailsModal from './FoodCardUserMemberFamilyDetailsModal'
import FoodCardUserMemberFamilyChangeCodeModal from './FoodCardUserMemberFamilyChangeCodeModal'
import FoodCardUserMemberFamilyChangeOldModal from './FoodCardUserMemberFamilyChangeOldModal'
import config from '../../config';
import FoodCardDetailsModal from '../foodCard/FoodCardDetailsModal';
import moment from 'moment';
import FoodCardGroupList from '../foodCardGroup/FoodCardGroupList';
import FoodCardUserMemberFamilyImportSaleforceExcelModal, { FoodCardUserMemberFamilyImportSaleforceExcelModalPropsType } from './FoodCardUserMemberFamilyImportSaleforceExcelModal';
import FoodCardUserMemberFamilyChangeDateModal from './FoodCardUserMemberFamilyChangeDateModal';

const { confirm } = Modal;
const { Paragraph } = Typography;

type EditAndAddModalType = FoodCardUserMemberFamilyEditAndAddType

interface PageState {
  isSpinLoading: boolean
  importSaleforceExcelModal?: {
    show: boolean
    key: any
  } & FoodCardUserMemberFamilyImportSaleforceExcelModalPropsType

  editAndAddModal: {
    show: boolean
    key: any
  } & EditAndAddModalType
  selectedRowKeys: string[]

  detailsModalProps?: {
    show: boolean
    key: any
    id: string
  }
  familyChangeCodeModalProps?: {
    show: boolean
    key: any
  }
  familyChangeOldModalProps?: {
    show: boolean
    key: any
    familyId: string
  }

  foodCardGroupListModalProps?: {
    show: boolean
    key: any
    familyId: string
  }

  foodCardDetailsModal: {
    show?: boolean
    key?: any
    foodCardCode: string
  }

  familyChangeDateModal: {
    show: boolean
    key: any
    id?: string
    type: string
  }

  memberFamily: GetFoodCardUserMemberFamilyRes[]
  memberFamilyAreas: GetFoodCardUserMemberFamilyAreaRes[]
  kioskVendings: GetKioskVendingRes[]
}
interface Props {
}


interface Page {

}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)




type TableOldData = (GetFoodCardUserMemberFamilyOldRes & { foodCard?: GetFoodCardRes })


type TableData = GetFoodCardUserMemberFamilyRes & {
  foodCardUserMembers: GetFoodCardUserMemberRes[]
  olds: TableOldData[]
}

class FoodCardUserMemberFamilyList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      memberFamily: [],
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      },
      selectedRowKeys: [],
      foodCardDetailsModal: {
        foodCardCode: '',
      },
      familyChangeDateModal: {
        show: false,
        key: new Date().toString(),
        type: '',
      },
      memberFamilyAreas: [],
      kioskVendings: [],
    }
  }
  tableRef?: JJ_Table<TableData> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      const familyAreaLisRes = await this._getFoodCardUserMemberFamilyAreaList({ count: 1000 })
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })

      this.setState({
        isSpinLoading: false,
        memberFamilyAreas: familyAreaLisRes.data,
        kioskVendings: kioskVendingList.data,

      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteFoodCardUserMemberFamily = async (params: DeleteFoodCardUserMemberFamilyParameters) => {
    const res = await api.DeleteFoodCardUserMemberFamily(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberList = async (params: GetFoodCardUserMemberListParameters) => {
    const res = await api.GetFoodCardUserMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _getFoodCardUserMemberFamilyAreaList = async (params: GetFoodCardUserMemberFamilyAreaListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyAreaList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberFamilyOldList = async (params: GetFoodCardUserMemberFamilyOldListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyOldList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardUserMemberFamilyResetFoodCardCodeSign = async (params: PostFoodCardUserMemberFamilyResetFoodCardCodeSignParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyResetFoodCardCodeSign(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _postFoodCardUserMemberFamilyChangePassword = async (params: PostFoodCardUserMemberFamilyChangePasswordParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyChangePassword(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _PostFoodCardUserMemberFamilyRenewal = async (params: PostFoodCardUserMemberFamilyRenewalParameters) => {
    const res = await api.PostFoodCardUserMemberFamilyRenewal(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _oldSubTable = (data: TableData) => {
    return (
      <JJ_Table<TableOldData>
        title={`舊卡`}
        key={`${data.id}${Date.now()}`}
        style={{ padding: 20, backgroundColor: 'rgba(0,0,0,0.1)' }}
        isSearchText={false}
        columns={[

          {
            title: `食品卡號碼`,
            dataIndex: 'foodCardCode',
            key: 'foodCardCode',
            width: 200,
          },
          {
            title: '狀態',
            dataIndex: 'foodCardStatus',
            key: 'foodCardStatus',
            width: 150,
            render: (value, record) => {
              if (!record.foodCard) {
                return
              }

              if (record.foodCard.isEnable !== undefined && !record.foodCard.isEnable) {
                return <Tag color="red">已停用</Tag>
              }

              if (record.foodCard.beginAt && moment().isBefore(moment(record.foodCard.beginAt), 'day')) {
                //如果現在時間小於開始時間  未開始
                // 20 < 21
                return <Tag color="red">未開始</Tag>
              }

              if (record.foodCard.endAt && moment().isSameOrAfter(moment(record.foodCard.endAt), 'day')) {
                //如果現在時間小於開始時間  未開始
                //20 >= 19
                return <Tag color="red">已結束</Tag>
              }

              return <Tag color="blue">生效中</Tag>

            }
          },

          {
            title: '啟用時間',
            dataIndex: 'beginAt',
            key: 'beginAt',
            render: (value, record) => {

              if (record.foodCard) {
                return record.foodCard.beginAt
                  ? (<JJ_TableItemMoment
                    date={record.foodCard.beginAt}
                  />)
                  : '--:--'
              }


            }

          },
          {
            title: '停用時間',
            dataIndex: 'endAt',
            key: 'endAt',
            render: (value, record) => {

              if (record.foodCard) {
                return record.foodCard.endAt
                  ? (<JJ_TableItemMoment
                    date={record.foodCard.endAt}
                  />)
                  : '--:--'
              }


            }

          },
        ]}
        onDataSource={async (body) => {
          return {
            data: data.olds,
            totalCount: data.olds.length
          }
        }}
        tableProps={{ pagination: false }}

      />
    )
  }

  _getStatusView = (status: GetFoodCardUserMemberFamilyStatusRes | string) => {

    switch (status) {
      case 'ENDED': {
        return <Tag color="blue">已完结</Tag>
      }
      case 'IN_SERVICE': {
        return <Tag color="success">服務中</Tag>
      }
      case 'NOT_SET': {
        return <Tag color="pink">未設置</Tag>
      }
      case 'PAUSED': {
        return <Tag color='warning'>暫停中</Tag>
      }
      case 'TERMINATED': {
        return <Tag color="red">已終止</Tag>
      }
      case 'WAITING': {
        return <Tag >未開始</Tag>
      }
      case 'INVISIBLE': {
        return <Tag color='pink'>隱形</Tag>
      }
      default: {
        return <div />
      }
    }
  }
  _getRenewalStatusView = (status: GetFoodCardUserMemberFamilyListParameters['renewalStatus']) => {

    switch (status) {
      case 'CAN_RENEWED': {
        return <Tag color='green'>能續期</Tag>
      }
      case 'RENEWED': {
        return <Tag color="blue">已續期</Tag>
      }
      case 'WAITING': {
        return <Tag color='default'>等待接納續期中</Tag>
      }
      case 'RENEWING': {
        return <Tag color='default'>日期等待結束續期中</Tag>
      }
      default: {
        return <div />
      }
    }
  }


  render() {
    return (
      <div id="FoodCardUserMemberFamilyList"
      >
        <Space>
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            disabled={!(this.state.selectedRowKeys.length > 0 && this.state.selectedRowKeys.every(id => {
              const family = this.state.memberFamily.find(item => item.id === id)
              return family?.status === 'NOT_SET' || family?.status === 'WAITING'
            }))}

            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'editsStart', ids: this.state.selectedRowKeys }
            }))}
            icon={<EditOutlined />}
          >
            {`批量修改家庭服務開始日期`}
          </Button>

          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            disabled={!(this.state.selectedRowKeys.length > 0 && this.state.selectedRowKeys.every(id => {
              const family = this.state.memberFamily.find(item => item.id === id)
              return family?.status !== 'TERMINATED'
            }))}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'editWhiteListFamilyKiosk', familyIds: this.state.selectedRowKeys }
            }))}
            icon={<EditOutlined />}
          >
            {`批量設置取餐機白名單`}
          </Button>

        </Space>
        {this.state.importSaleforceExcelModal && (
          <Modal
            maskClosable={false}
            title={'從Saleforce xlsx導入家庭'}
            visible={this.state.importSaleforceExcelModal.show}
            footer={null}
            onCancel={() => {
              const importSaleforceExcelModal = this.state.importSaleforceExcelModal
              if (importSaleforceExcelModal) {
                this.setState({
                  importSaleforceExcelModal: { ...importSaleforceExcelModal, show: false }
                })
              }
            }}
            width={'85%'}
          >
            <FoodCardUserMemberFamilyImportSaleforceExcelModal
              {...this.state.importSaleforceExcelModal}
              onFinish={async (props, id) => {
                const importSaleforceExcelModal = this.state.importSaleforceExcelModal
                if (importSaleforceExcelModal) {
                  this.setState({
                    importSaleforceExcelModal: { ...importSaleforceExcelModal, show: false }
                  })
                }

                switch (props.type) {
                  case 'batch-add-excel': {
                    this.tableRef && await this.tableRef.refreshData()
                    message.success(`從Saleforce xlsx導入家庭成功`)
                  }
                    break
                }

              }}
            />
          </Modal>
        )}


        <Modal
          maskClosable={false}
          title={(() => {
            switch (this.state.editAndAddModal.type) {
              case 'add': {
                return '新建用戶家庭'
              }
              case 'edit': {
                return '編輯用戶家庭'
              }
              case 'editsStart': {
                return '批量修改家庭服務開始日期'
              }
              case 'editWhiteListFamilyKiosk': {
                return '設置取餐機白名單'
              }
            }
          })()}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <FoodCardUserMemberFamilyEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props, id) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                  this.setState({
                    detailsModalProps: {
                      show: true,
                      key: Date.now(),
                      id
                    }
                  })

                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
                  break
                case 'editsStart': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`批量修改家庭服務開始日期成功`)
                }
                  break

                case 'editWhiteListFamilyKiosk': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`取餐機白名單設置成功`)
                }
                  break
              }

            }}
          />
        </Modal>

        {this.state.foodCardGroupListModalProps && (
          <Modal
            maskClosable={false}
            // title={this.state.editAndAddModal.type === 'edit' ? '編輯用戶家庭' : '新建用戶家庭'}
            visible={this.state.foodCardGroupListModalProps.show}
            footer={null}
            onCancel={() => {
              const foodCardGroupListModalProps = this.state.foodCardGroupListModalProps
              if (foodCardGroupListModalProps) {
                this.setState({
                  foodCardGroupListModalProps: { ...foodCardGroupListModalProps, show: false }
                })
              }
            }}
            width={'85%'}
          >
            <FoodCardGroupList
              key={this.state.foodCardGroupListModalProps.key}
              foodCardUserMemberFamilyId={this.state.foodCardGroupListModalProps.familyId}
            />
          </Modal>
        )}

        {this.state.foodCardDetailsModal.key && (
          <FoodCardDetailsModal
            key={this.state.foodCardDetailsModal.key}
            title={'食品卡詳情頁'}
            visible={!!this.state.foodCardDetailsModal.show}
            onCancel={() => this.setState({ foodCardDetailsModal: { ...this.state.foodCardDetailsModal, show: false } })}
            foodCardCode={this.state.foodCardDetailsModal.foodCardCode}
          />
        )}

        {this.state.detailsModalProps && (
          <FoodCardUserMemberFamilyDetailsModal
            id={this.state.detailsModalProps.id}
            title={'用戶家庭詳情'}
            visible={this.state.detailsModalProps.show}
            key={this.state.detailsModalProps.key}
            onCancel={async () => {
              if (this.state.detailsModalProps) {
                this.setState({
                  detailsModalProps: { ...this.state.detailsModalProps, show: false }
                })
                this.tableRef && await this.tableRef.refreshData()
              }
            }}
          />
        )}

        {this.state.familyChangeCodeModalProps && (
          <FoodCardUserMemberFamilyChangeCodeModal
            title={'批量分配家庭到取餐機'}
            visible={this.state.familyChangeCodeModalProps.show}
            key={this.state.familyChangeCodeModalProps.key}
            onCancel={async () => {
              if (this.state.familyChangeCodeModalProps) {
                this.setState({
                  familyChangeCodeModalProps: { ...this.state.familyChangeCodeModalProps, show: false }
                })
              }
            }}
            onSave={async (ids) => {
              if (this.state.familyChangeCodeModalProps) {
                this.setState({
                  familyChangeCodeModalProps: { ...this.state.familyChangeCodeModalProps, show: false }
                })
                this.tableRef && await this.tableRef.refreshData()
              }
            }}
          />
        )}

        {this.state.familyChangeDateModal.id && (
          <FoodCardUserMemberFamilyChangeDateModal
            id={this.state.familyChangeDateModal.id}
            visible={this.state.familyChangeDateModal.show}
            type={this.state.familyChangeDateModal.type}
            key={this.state.familyChangeDateModal.key}
            onOk={async () => {
              this.tableRef && await this.tableRef.refreshData()
              message.success(`更新成功`)

              this.setState({
                familyChangeDateModal: {
                  ...this.state.familyChangeDateModal,
                  show: false,
                },
              })
            }}
            onCancel={() => {
              this.setState({
                familyChangeDateModal: {
                  ...this.state.familyChangeDateModal,
                  show: false,
                },
              })
            }}
          />
        )}

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<TableData, {
              desc: 'ascend' | 'descend'
              createAt?: [moment.Moment, moment.Moment] | null
              areaId?: string
              kioskVendingIds?: string[]
              renewalStatus?: GetFoodCardUserMemberFamilyListParameters['renewalStatus']
              status?: GetFoodCardUserMemberFamilyListParameters['status']
              phase?: GetFoodCardUserMemberFamilyListParameters['phase']
              searchTimeType?: GetFoodCardUserMemberFamilyListParameters['searchTimeType']
              whiteListFamilyKioskEnabled?: 'false' | 'true'
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  span: 2,
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },


                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意篩選時間類型',
                  key: 'searchTimeType',
                  allowClear: true,
                  span: 4,
                  options:
                    [
                      { value: 'appliedAt', title: '申請時間' },
                      { value: 'createdAt', title: '創建時間' },
                      { value: 'beginAt', title: '食品卡啟動時間' },
                      { value: 'endAt', title: '食品卡結束時間' },
                    ].map(item => ({
                      value: item.value,
                      name: item.title,
                      disabled: false,
                    }))
                },

                {
                  type: 'rangePicker',
                  key: 'createAt',
                  span: 4,

                  props: {
                    placeholder: ['開始日期', '結束日期']

                  }
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'areaId',
                  placeholder: '請選擇任意區域',
                  allowClear: true,
                  showSearch: true,
                  span: 3,
                  filterOption: (input: string, option: any) => {
                    if (option && option.children.props.children.length) {
                      const text = option.children.props.children[0].props.children + '' + option.children.props.children[1].props.children;
                      return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                  },
                  options: this.state.memberFamilyAreas.map(area => {
                    return {
                      value: area.id,
                      name: (
                        <>
                          <span>{area.title}</span>
                          <Tag style={{ marginLeft: 8 }}>{area.code}</Tag>
                        </>
                      ),
                      disabled: false,
                    }
                  })
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  mode: 'multiple',
                  key: 'kioskVendingIds',
                  placeholder: '請選擇任意取餐機',
                  allowClear: true,
                  span: 3,
                  filterOption: (input, option) => {
                    if (option && option.key) {
                      return (this.state.kioskVendings[Number(option.key)].name as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        (this.state.kioskVendings[Number(option.key)].code as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  },
                  options: this.state.kioskVendings.map(kioskVending => {
                    return {
                      value: kioskVending.id,
                      name: (
                        <Space align='center' >
                          <Tag color={'orange'}>{kioskVending.name}</Tag>
                          <Tag color={'blue'}>{kioskVending.code}</Tag>
                        </Space>
                      ),
                      disabled: false,
                    }
                  }),
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '版本號',
                  key: 'phase',
                  allowClear: true,
                  span: 2,
                  options: [
                    '1.0',
                    '2.0',
                  ].map(item => ({
                    value: item,
                    name: item,
                    disabled: false,
                  }))
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意狀態',
                  key: 'status',
                  allowClear: true,
                  span: 3,
                  options: [
                    'WAITING',
                    'IN_SERVICE',
                    'ENDED',
                    'TERMINATED',
                    'PAUSED',
                    'NOT_SET',
                    'INVISIBLE',
                  ].map(item => ({
                    value: item,
                    name: this._getStatusView(item),
                    disabled: false,
                  }))
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意續期狀態',
                  key: 'renewalStatus',
                  allowClear: true,
                  span: 3,
                  options: ([
                    "WAITING",
                    "RENEWED",
                    "RENEWING",
                    "CAN_RENEWED"
                  ]).map((item: any) => ({
                    value: item,
                    name: this._getRenewalStatusView(item),
                    disabled: false,
                  }))
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'whiteListFamilyKioskEnabled',
                  placeholder: '是否顯示取餐機白名單',
                  allowClear: true,
                  span: 4,
                  options: [
                    {
                      value: 'true',
                      name: <Tag color="blue">啟用</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'false',
                      name: <Tag color="red">關閉</Tag>,
                      disabled: false,
                    },

                  ]
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: record.status === 'TERMINATED',
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },

                          {
                            type: 'item',
                            disabled: record.status === 'TERMINATED',
                            key: 'editWhiteListFamilyKiosk',
                            name: '設置取餐機白名單',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'editWhiteListFamilyKiosk', familyIds: [record.id], key: new Date().toString() }
                              }))
                            }
                          },

                          // {
                          //   type: 'item',
                          //   disabled: !!!record.foodCardCode,
                          //   key: 'change-old',
                          //   name: '更換新卡',
                          //   onAction: () => {
                          //     this.setState(state => ({
                          //       familyChangeOldModalProps: { ...state.familyChangeOldModalProps, show: true, key: new Date().toString(), familyId: record.id }
                          //     }))
                          //   }
                          // },
                          {
                            type: 'item',
                            disabled: !!!(record.foodCardUserMembers[0] && record.foodCardUserMembers[0].foodCardCode),
                            key: 'showFoodCardDetails',
                            name: '顯示成員食品卡詳情',
                            onAction: () => {
                              const foodCardCode = record.foodCardUserMembers[0].foodCardCode
                              if (foodCardCode) {
                                this.setState(state => ({
                                  foodCardDetailsModal: { ...state.foodCardDetailsModal, show: true, key: new Date().toString(), foodCardCode }
                                }))
                              }
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'details',
                            name: '查看成員',
                            onAction: () => {
                              this.setState(state => ({
                                detailsModalProps: {
                                  show: true,
                                  id: record.id,
                                  key: new Date().toString()
                                }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'showFoodCardGroup',
                            name: '查看八達通',
                            onAction: () => {
                              this.setState(state => ({
                                foodCardGroupListModalProps: {
                                  show: true,
                                  familyId: record.id,
                                  key: new Date().toString()
                                }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'changePassword',
                            name: '更改用戶密碼',
                            onAction: () => {
                              confirm({
                                title: `是否要更改 “${record.phone}” 密碼？`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    const changePasswordRes = await this._postFoodCardUserMemberFamilyChangePassword({ foodCardUserMemberFamilyId: record.id })
                                    message.success(`${record.phone} 更改成功`)
                                    Modal.success({
                                      title: `${changePasswordRes.password}`,
                                      onOk() { },
                                    });

                                  } catch (error) {
                                    message.error(`${record.phone} 更改失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });

                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: '_postFoodCardUserMemberFamilyResetFoodCardCodeSign',
                            name: '刷新取餐二維碼',
                            onAction: () => {
                              confirm({
                                title: `是否要刷新 “${record.phone}” 取餐二維碼？`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await this._postFoodCardUserMemberFamilyResetFoodCardCodeSign({ id: record.id })
                                    message.success(`${record.phone} 更改成功`)
                                  } catch (error) {
                                    message.error(`${record.phone} 失敗 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });

                            }
                          },
                          {
                            type: 'item',
                            disabled:
                              record.renewalCount > 0 ||
                              !(record.status === 'ENDED' || record.status === 'IN_SERVICE') ||
                              !!record.renewalSentAt
                            ,
                            key: 'renewal',
                            name: '通知續期',
                            onAction: () => {
                              confirm({
                                title: `是否要通知 “${record.phone}” 續期`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    const renewal = await this._PostFoodCardUserMemberFamilyRenewal({ id: record.id })
                                    message.success(`${record.phone} 通知續期成功`)
                                    this.tableRef && this.tableRef.refreshData()
                                  } catch (error) {
                                    message.error(`${record.phone} 通知續期失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });

                            }
                          },

                          {
                            type: 'item',
                            disabled:
                              !(record.status === 'NOT_SET' || record.status === 'WAITING')
                            ,
                            key: 'start',
                            name: '修改服務開始日期',
                            onAction: () => {
                              this.setState({
                                familyChangeDateModal: {
                                  key: new Date().toString(),
                                  id: record.id,
                                  show: true,
                                  type: 'start',
                                },
                              })
                            }
                          },

                          {
                            type: 'item',
                            disabled:
                              !(record.status === 'IN_SERVICE' || record.status === 'PAUSED')
                            ,
                            key: 'pausedOrResume',
                            name: record.status === 'IN_SERVICE' ? '立即暫停服務' : '立即恢復服務',
                            onAction: () => {
                              this.setState({
                                familyChangeDateModal: {
                                  key: new Date().toString(),
                                  id: record.id,
                                  show: true,
                                  type: record.status === 'IN_SERVICE' ? 'paused' : 'resume',
                                },
                              })
                            }
                          },

                          {
                            type: 'item',
                            disabled:
                              (record.status === 'TERMINATED')
                            ,
                            key: 'terminated',
                            name: '強制終止服務',
                            onAction: () => {
                              this.setState({
                                familyChangeDateModal: {
                                  key: new Date().toString(),
                                  id: record.id,
                                  show: true,
                                  type: 'terminated',
                                },
                              })
                            }
                          },

                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },


                {
                  title: 'JcConsole',
                  dataIndex: 'jcConsole',
                  key: 'jcConsole',
                  width: 150,
                },

                {
                  title: '食品卡家庭號',
                  dataIndex: 'foodCardCode',
                  key: 'foodCardCode',
                  width: 200,
                  render: (value, record) => {

                    if (!record.foodCardCode) {
                      return
                    }
                    return (
                      <Tag color='blue'>{record.foodCardCode}</Tag>
                    )

                  }
                },

                {
                  title: '中文姓名',
                  dataIndex: 'fullNameChinese',
                  key: 'fullNameChinese',
                  width: 200,
                  render: (value, record) => {

                    if (record.foodCardUserMembers.length <= 0) {
                      return
                    }
                    return record.foodCardUserMembers[0].fullNameChinese
                  }
                },

                {
                  title: '英文姓名',
                  dataIndex: 'firstname',
                  key: 'firstname',
                  width: 200,
                  render: (value, record) => {

                    if (record.foodCardUserMembers.length <= 0) {
                      return
                    }
                    return `${record.foodCardUserMembers[0].lastname || ''} ${record.foodCardUserMembers[0].firstname || ''}`
                  }
                },

                {
                  title: '電話號碼',
                  dataIndex: 'phone',
                  key: 'phone',
                  width: 120,
                  render: (value, record) => {
                    const [areaCode, phoneCode] = String(record.phone).split('-')
                    if (phoneCode) {
                      return phoneCode
                    }
                  }
                },


                {
                  title: '狀態',
                  dataIndex: 'status',
                  key: 'status',
                  width: 120,
                  render: (value, record) => {
                    return this._getStatusView(value)
                  }
                },

                {
                  title: '取餐機白名單',
                  dataIndex: 'whiteListFamilyKioskEnabled',
                  key: 'whiteListFamilyKioskEnabled',
                  width: 120,
                  render: (value, record) => {
                    return (
                      value
                        ? <Tag color="blue">啟用</Tag>
                        : <Tag color="red">關閉</Tag>
                    )
                  }
                },

                {
                  title: '啟動時間',
                  dataIndex: 'beginAt',
                  key: 'beginAt',
                  width: 120,
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
                {
                  title: '結束時間',
                  dataIndex: 'endAt',
                  key: 'endAt',
                  width: 120,
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '續期次數',
                  dataIndex: 'renewalCount',
                  key: 'renewalCount',
                  width: 100,
                },

                {
                  title: '續期發起人',
                  dataIndex: ['renewalSentUser', 'nickname'],
                  key: 'renewalSentUser.nickname',
                  width: 120,
                },
                {
                  title: '續期發起時間',
                  dataIndex: ['renewalSentAt'],
                  key: 'renewalSentAt',
                  width: 120,
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },

                {
                  title: '額外天數',
                  dataIndex: 'extraDays',
                  key: 'extraDays',
                  width: 100,
                },



                {
                  title: '申請日期',
                  dataIndex: 'appliedAt',
                  key: 'appliedAt',
                  width: 110,
                  render: (value) => {
                    if (value) {
                      return (
                        <JJ_TableItemMoment
                          date={value}
                        />
                      )
                    }
                  }
                },

                {
                  title: '用戶地址',
                  width: 200,
                  dataIndex: 'address',
                  key: 'address',
                  render: (value) =>
                    <div
                      style={{
                        maxHeight: 70,
                        overflow: 'auto',
                      }}>
                      {value}
                    </div>
                },

                {
                  title: '家庭區域',
                  width: 150,
                  dataIndex: ['area', 'title'],
                  key: 'area.title',
                  render: (value) =>
                    <div
                      style={{
                        maxHeight: 70,
                        overflow: 'auto',
                      }}>
                      {value}
                    </div>
                },
                {
                  title: 'Whatsapp',
                  dataIndex: 'whatsapp',
                  key: 'whatsapp',
                  width: 150,
                },
                {
                  title: '版本號',
                  dataIndex: 'phase',
                  key: 'phase',
                  width: 100,
                },
                {
                  title: '社工',
                  dataIndex: 'coordinator',
                  key: 'coordinator',
                  width: 150,
                },

                {
                  title: '電郵',
                  dataIndex: 'email',
                  key: 'email',
                  width: 200,
                },
                {
                  title: '備註',
                  dataIndex: 'remark',
                  key: 'remark',
                  width: 200,
                  render: (value, record) => {
                    return (
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          expandable: true,
                        }}
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {value}
                      </Paragraph>
                    )
                  }
                },

                {
                  title: 'SFID',
                  dataIndex: 'sfid',
                  key: 'sfid',
                  width: 150,
                  render: (value) => (
                    !!value && <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '創建時間',
                  width: 120,
                  fixed: 'right',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'用戶家庭列表'}
              tableProps={{
                scroll: {
                  x: 1200,
                },
                rowSelection: {
                  onChange: (selectedRowKeys: any) => {
                    this.setState({ selectedRowKeys })
                  },
                  selectedRowKeys: this.state.selectedRowKeys,
                },
              }}

              expandable={{
                expandedRowRender: (record) => this._oldSubTable(record),
                rowExpandable: (record) => record.olds && record.olds.length > 0
              }}

              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardUserMemberFamilyListParameters['isAscend']
                  searchTimeType?: GetFoodCardUserMemberFamilyListParameters['searchTimeType']
                  beginAt?: GetFoodCardUserMemberFamilyListParameters['beginAt']
                  endAt?: GetFoodCardUserMemberFamilyListParameters['endAt']
                  areaId?: GetFoodCardUserMemberFamilyListParameters['areaId']
                  scope?: GetFoodCardUserMemberFamilyListParameters['scope']
                  kioskVendingIds?: GetFoodCardUserMemberFamilyListParameters['kioskVendingIds']
                  renewalStatus?: GetFoodCardUserMemberFamilyListParameters['renewalStatus']
                  status?: GetFoodCardUserMemberFamilyListParameters['status']
                  phase?: GetFoodCardUserMemberFamilyListParameters['phase']
                  whiteListFamilyKioskEnabled?: GetFoodCardUserMemberFamilyListParameters['whiteListFamilyKioskEnabled']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  if (sourceItemBody.kioskVendingIds && sourceItemBody.kioskVendingIds.length > 0) {
                    params.kioskVendingIds = sourceItemBody.kioskVendingIds
                  }

                  if (sourceItemBody.renewalStatus) {
                    params.renewalStatus = sourceItemBody.renewalStatus
                  }

                  if (sourceItemBody.status) {
                    params.status = sourceItemBody.status
                  }

                  if (sourceItemBody.searchTimeType) {
                    params.searchTimeType = sourceItemBody.searchTimeType
                  }
                  if (sourceItemBody.phase) {
                    params.phase = sourceItemBody.phase
                  }

                  if (sourceItemBody.whiteListFamilyKioskEnabled) {
                    params.whiteListFamilyKioskEnabled = sourceItemBody.whiteListFamilyKioskEnabled === 'true'

                  }


                  const createAt = sourceItemBody.createAt
                  if (createAt && createAt.length === 2) {
                    const [beginAtMoment, endAtMoment,] = createAt
                    params.beginAt = moment(beginAtMoment).startOf('day').toISOString()
                    params.endAt = moment(endAtMoment).endOf('day').toISOString()
                  }

                  params.areaId = sourceItemBody.areaId


                }




                const res = await api.GetFoodCardUserMemberFamilyList({
                  ...body,
                  ...params,
                  scope: ['area', 'renewalSentUser'],
                })




                if (res.kind !== 'ok') throw new Error(res.kind)


                this.setState({
                  memberFamily: res.data.data
                })

                const totalCount = res.data.totalCount


                const data = await Promise.all(

                  res.data.data.map(async item => {

                    const memberListRes = await this._getFoodCardUserMemberList({ foodCardUserMemberFamilyId: item.id, isAscend: false })
                    // const familyOldListRes = await this._getFoodCardUserMemberFamilyOldList({ count: 1000, foodCardUserMemberFamilyId: item.id })


                    return {
                      ...item,
                      foodCardUserMembers: memberListRes.data,
                      olds: [],
                    }
                  })

                )

                return {
                  totalCount,
                  data,
                }
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(FoodCardUserMemberFamilyList)

