import React, { ReactElement } from 'react';
import { Layout, Menu, Spin, Space, Dropdown, Button, Drawer } from 'antd';
import { Link, RouteChildrenProps } from 'react-router-dom';
import {
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import config from '../../config';
import MainContent from './MainContent';
import { connect, ConnectedProps } from 'react-redux';
import { ActionUserRes, getUser } from '../../actions/ActionUser';
import api, { PutUserParameters } from '../../api';
import UserAuthPasswordChangeModal from './UserAuthPasswordChangeModal';
import { logoFoodAngel, logoFoodAngelSit, logoFoodAngelUat } from '../../imgs';
import { ActionConfigRes, setConfigData } from '../../actions/ActionConfig';
const { SubMenu } = Menu;
const { Header, Sider } = Layout;

export interface menuSubData {
  key: string
  title: string
  icon?: ReactElement | null
  match?: string
}

export interface menuData {
  key: string
  title: string
  icon?: ReactElement
  match?: string
  sub?: menuSubData[]
}

export interface PageState {
  collapsed: boolean
  firstHide?: boolean
  menuData: menuData[]
  openKeys: string[]
  selectedKey: string
  loading?: boolean
  mainContentKey?: string
  passwordChangeModal: {
    visible: boolean
    key?: string
  }
  windowSizeWidth?: number
  windowSizeHeight?: number
}

export interface Props {

}



export type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

export interface Page {
  _initData(): Promise<void>
}


interface RootState {
  user: ActionUserRes
  config: ActionConfigRes
}

const mapState = (state: RootState) => ({
  user: state.user,
  config: state.config,
})


const mapDispatch = {
  setConfigData: setConfigData,
  getUser: getUser,
}

export const connector = connect(
  mapState,
  mapDispatch
)


class Main extends React.Component<PageProps, PageState> implements Page {


  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      passwordChangeModal: {
        visible: false,
      },
      collapsed: false,
      menuData: [
        {
          key: '/area',
          title: '區域及社區',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/area/list',
              title: '區域清單',
              match: '^\/area\/list[\/|a-z]*$',
            },
            {
              key: '/community/list',
              title: '社區清單',
              match: '^\/community\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/partner-company',
          title: '合作機構',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/partner-company/list',
              title: '合作機構清單',
              match: '^\/partner-company\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/kiosk-vending',
          title: '取餐機',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/kiosk-vending/list',
              title: '取餐機清單',
              match: '^\/kiosk-vending\/list[\/|a-z]*$',
            },
            {
              key: '/kiosk-vending/sell-week/list',
              title: '每週銷售統計',
              match: '^\/kiosk-vending\/sell-week\/list[\/|a-z]*$',
            },
            {
              key: '/kiosk-vending/member-month/list',
              title: '每月會員統計',
              match: '^\/kiosk-vending\/member-month\/list[\/|a-z]*$',
            }
          ]
        },

        {
          key: '/quota',
          title: '配额',
          sub: [
            {
              key: '/quota/list',
              title: '配额清單',
              match: '^\/quota\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/food-card-user-member-family',
          title: '用戶家庭',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/food-card-user-member-family/signup-form/list',
              title: '申請個案',
              match: '^\/food-card-user-member-family\/signup-form\/list[\/|a-z]*$',
            },
            {
              key: '/food-card-user-member-family/list',
              title: '用戶家庭清單',
              match: '^\/food-card-user-member-family\/list[\/|a-z]*$',
            },
            {
              key: '/food-card-user-member/list',
              title: '用戶家庭成員清單',
              match: '^\/food-card-user-member\/list[\/|a-z]*$',
            },
            {
              key: '/food-card-user-member-family-area/list',
              title: '用戶家庭區域清單',
              match: '^\/food-card-user-member-family-area\/list[\/|a-z]*$',
            },
          ],
        },

        {
          key: '/food-card',
          title: '食品卡',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/food-card/list',
              title: '食品卡清單',
              match: '^\/food-card\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/food-card-record',
          title: '食品卡取餐記錄',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/food-card-record/list',
              title: '食品卡取餐記錄清單',
              match: '^\/food-card-record\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/food-card-group',
          title: '八達通',
          // icon: <MenuUnfoldOutlined />,
          sub: [
            {
              key: '/food-card-group/list',
              title: '八達通清單',
              match: '^\/food-card-group\/list[\/|a-z]*$',
            },
          ],
        },

        {
          key: '/message',
          title: 'App系統消息',
          sub: [
            {
              key: '/message',
              title: 'App系統消息清單',
              match: '^\/message\/message[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/replenish-request',
          title: '補貨單',
          sub: [
            {
              key: '/replenish-request',
              title: '補貨單',
              match: '^\/replenish-request\/replenish-request[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/product',
          title: '產品',
          sub: [
            {
              key: '/product/list',
              title: '產品清單',
              match: '^\/product\/list[\/|a-z]*$',
            },
            {
              key: '/product-label/list',
              title: '產品標籤清單',
              match: '^\/product-label\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/health-tip',
          title: '健康提示',
          sub: [
            {
              key: '/health-tip/list',
              title: '健康提示清單',
              match: '^\/health-tip\/list[\/|a-z]*$',
            },

          ],
        },
        {
          key: '/image-library',
          title: '圖片庫',
          sub: [
            {
              key: '/image-library/list',
              title: '圖片庫清單',
              match: '^\/image-library\/list[\/|a-z]*$',
            },
          ],
        },
        {
          key: '/survey',
          title: '問卷調查',
          sub: [
            {
              key: '/survey/list',
              title: '問卷調查清單',
              match: '^\/survey\/list[\/|a-z]*$',
            },
          ],
        },
      ],

      openKeys: this._getOpenKeys(props),
      selectedKey: '',

    };
  }

  _getOpenKeys = (props: PageProps) => {
    return [`/${props.location.pathname.split('/')[1] || ''}`]
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowSize())

    this.setState = () => { }
  }

  componentDidMount() {
    this._initData()

    window.addEventListener('resize', () => this.updateWindowSize())
  }


  updateWindowSize() {
    this.setState({
      windowSizeWidth: window.innerWidth,
      windowSizeHeight: window.innerHeight,
    })
  }


  _getUser = async () => {
    const res = await this.props.getUser()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _putUser = async (params: PutUserParameters) => {
    const res = await api.PutUser(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }



  _deleteAuth = async () => {
    await api.DeleteAuth()
    return
  }

  async _initData(): Promise<void> {
    try {
      this.setState({ loading: true })
      await this._getUser()
      this.setState({ loading: false, mainContentKey: new Date().toISOString() })
    } catch (error) {
      console.log(error.message)
      if (error.message)
        this.props.history.replace('/login')
      this.setState({ loading: false })
    }
  }


  _onOpenChange = (value: string[]) => {

    this.setState({
      openKeys: [value[value.length - 1]],
      firstHide: false
    })

  };

  _getMenuView = () => {
    return (
      <Menu
        className='menu'
        selectedKeys={[this.props.location.pathname]}
        openKeys={this.state.openKeys}
        theme="dark"
        mode="inline"
        onOpenChange={(value: any) => this._onOpenChange(value)}
      >
        {
          this.state.menuData.map((menu) => {

            if (menu.sub) {
              return (
                <SubMenu
                  className='subMenu'
                  key={menu.key}
                  title={
                    <span>
                      {menu.icon}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {menu.sub.map((menuSub) => {
                    return (
                      <Menu.Item
                        className='subMenuItem'
                        key={menuSub.key}>
                        <Link to={menuSub.key}>
                          {menuSub.icon}
                          {menuSub.title}
                        </Link>
                      </Menu.Item>
                    )
                  })}
                </SubMenu>
              )
            } else {

              return (
                <Menu.Item
                  className='subMenuItem'
                  key={menu.key}
                >
                  <Link to={menu.key}>
                    {menu.icon}
                    <span>{menu.title}</span>
                  </Link>
                </Menu.Item>
              )

            }
          })
        }
      </Menu>
    )

  }

  render() {


    return (
      <div id="main">
        <Spin
          spinning={this.state.loading}
        >
          <Layout className='layout' style={{ minHeight: '100vh' }}>

            {this.state.windowSizeWidth && this.state.windowSizeWidth <= 767
              ? (

                <Drawer
                  className='drawer'
                  placement="left"
                  width="auto"
                  closable={false}
                  visible={!this.state.collapsed}
                  onClose={() => this.setState({ collapsed: true })}
                >
                  <Sider
                    className='sider'
                    trigger={null}
                    style={{
                      height: '100vh',
                    }}
                  >
                    <div className="logo" >
                      <div
                        className='logoName'
                      >
                        Food Angel
                    </div>
                    </div>
                    {this._getMenuView()}
                  </Sider>
                </Drawer>

              )
              : (
                <Sider
                  className='sider'
                  collapsible
                  collapsed={this.state.collapsed}
                  trigger={null}
                  style={{ overflowX: 'hidden' }}
                >
                  <div className="logo" >
                    <img
                      className='logoImg'
                      src={config.REACT_APP_DEV_ENV === 'sit' ? logoFoodAngelSit : (
                        config.REACT_APP_DEV_ENV === 'uat' ? logoFoodAngelUat : logoFoodAngel
                      )}
                    />
                  </div>
                  {this._getMenuView()}
                </Sider>
              )
            }


            <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: '0px 16px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }} >

                <Space align='center' >

                  <Button
                    icon={this.state.collapsed
                      ? (<MenuUnfoldOutlined />)
                      : (<MenuFoldOutlined />)}
                    onClick={() => {
                      this.setState({
                        collapsed: !this.state.collapsed,
                        openKeys: this._getOpenKeys(this.props)
                      })
                    }}

                  />
                </Space>


                <Space align='center' >
                  <Dropdown
                    overlay={(
                      <Menu>
                        <Menu.Item
                          key="0"
                          disabled={true}
                        >{this.props.user.data.nickname}</Menu.Item>

                        {/*<Menu.Divider />
                        {[
                          { title: "繁體中文", value: 'zh-tw' },
                          { title: "English", value: 'en' },
                          { title: "简体中文", value: 'zh-cn' },
                        ].map((item) => {
                          return (
                            <Menu.Item
                              key={item.value}
                              onClick={async () => {
                                const language: any = item.value
                                await this._putUser({ language, id: this.props.user.data.id })
                                window.location.reload()
                              }}
                              disabled={item.value === this.props.user.data.language}
                            >
                              {item.title}
                            </Menu.Item>
                          )
                        })}*/}

                        <Menu.Divider />
                        {[
                          { title: "時間模式(YYYY-MM-DD HH:mm:ss)", value: 'format' },
                          { title: "時間模式(從現在起)", value: 'fromNow' },
                        ].map((item: any) => {
                          return (
                            <Menu.Item
                              key={item.value}
                              onClick={async () => {
                                this.props.setConfigData({
                                  ...this.props.config,
                                  momentType: item.value,
                                })
                              }}
                              disabled={item.value === this.props.config.momentType}
                            >
                              {item.title}
                            </Menu.Item>
                          )
                        })}

                        <Menu.Divider />
                        <Menu.Item
                          key="1"
                          disabled={false}
                          onClick={() => this.setState({ passwordChangeModal: { ...this.state.passwordChangeModal, key: new Date().toISOString(), visible: true } })}
                        >修改密碼</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          danger
                          onClick={async () => {
                            try {
                              this.setState({
                                loading: true,
                              })
                              await this._deleteAuth()
                              this.setState({
                                loading: false,
                              })
                              this.props.history.replace('/login')
                            } catch (error) {
                              this.setState({
                                loading: false,
                              })
                            }
                          }}
                          key="2">登出</Menu.Item>
                      </Menu>
                    )}
                    trigger={['click']}
                  >
                    <Button icon={(<SettingOutlined />)} />
                  </Dropdown>
                </Space>
              </Header>
              {this.state.mainContentKey && <MainContent key={this.state.mainContentKey} />}
            </Layout>
          </Layout>
          {
            this.state.passwordChangeModal.key && (
              <UserAuthPasswordChangeModal
                key={this.state.passwordChangeModal.key}
                title='修改密碼'
                userId={this.props.user.data.id}
                visible={this.state.passwordChangeModal.visible}
                onCancel={() => this.setState({ passwordChangeModal: { ...this.state.passwordChangeModal, visible: false } })}
                onFinish={() => {
                  this.setState({ passwordChangeModal: { ...this.state.passwordChangeModal, visible: false } })
                }}
              />
            )
          }

        </Spin >
      </div >
    )
  }

}
export default connector(Main)
