import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetMessageParameters, PutMessageParameters, PostMessageParameters, GetMessageListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Input, Select, Tag } from 'antd';

interface PageState {

}


export type MessageEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & MessageEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class MessageEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putMessage({
          ...values,
          id: this.props.id,
          maxCount: values.maxCount || undefined,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const res = await this._postMessage({
          ...values,
          maxCount: values.maxCount || undefined,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getMessage({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {

        return {
        type:'user'
        }
      }


    }
  };

  _getMessage = async (param: GetMessageParameters) => {
    const res = await api.GetMessage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getMessageList = async (param: GetMessageListParameters) => {
    const res = await api.GetMessageList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putMessage = async (param: PutMessageParameters) => {
    const res = await api.PutMessage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postMessage = async (param: PostMessageParameters) => {
    const res = await api.PostMessage(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nAndDefaultText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'i18nAndDefaultTextArea',
            name: 'content',
            label: '內容',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'linkUrl',
            label: '鏈接',
            rules: [
              {
                required: false,
                message: `請輸入鏈接`,
              }
            ],
          },

          {
            id: 'type',
            label: '類型',
            rules: [
              {
                required: true,
                message: `請選擇類型`,
              }
            ],
            componet: (
              <Select
                showSearch={true}
              >
                {
                  [
                    { title: <Tag color='blue'>系統訊息</Tag>, value: 'system' },
                    { title: <Tag color='green'>個人訊息</Tag>, value: 'user' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                      >
                        {item.title}
                      </Select.Option>
                    )
                  })
                }
              </Select>
            ),
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {

        return [
          {
            id: 'i18nAndDefaultText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'i18nAndDefaultTextArea',
            name: 'content',
            label: '內容',
            rules: [
              {
                required: true,
              }
            ],
          },
          {
            id: 'linkUrl',
            label: '鏈接',
            rules: [
              {
                required: false,
                message: `請輸入鏈接`,
              }
            ],
          },

          {
            id: 'type',
            label: '類型',
            rules: [
              {
                required: true,
                message: `請選擇類型`,
              }
            ],
            componet: (
              <Select
                showSearch={true}
              >
                {
                  [
                    { title: <Tag color='blue'>系統</Tag>, value: 'system' },
                    { title: <Tag color='green'>手動</Tag>, value: 'user' },
                  ].map(item => {
                    return (
                      <Select.Option
                        key={item.value}
                        value={item.value}
                      >
                        {item.title}
                      </Select.Option>
                    )
                  })
                }
              </Select>
            ),
          },

          {
            id: 'remark',
            label: '備註',
            rules: [
              {
                required: false,
                message: `請輸入 備註`
              }
            ],
          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="MessageEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(MessageEditAndAdd)
